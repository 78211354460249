<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <select v-model="departement" class="w3-select w3-border w3-theme w3-round w3-small" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>
        <div class="w3-container w3-theme-l5 w3-round-large w3-padding scrlt-y" v-bind:style="'height:'+  (screenHeight-95) +'px;'">
            <div class="w3-row">
                <div class="w3-col l7 w3-margin-bottom">
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-blue w3-padding">
                                <div class="w3-left"><i class="fa fa-users w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Online / Offline Customer</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    <span class="w3-round-large" style="background: green;padding: 5px;" >{{card.custon}}</span> / <span class="w3-round-large" style="background: red;padding: 5px;">{{card.custoff}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w3-row">
                        <table class="w3-table w3-bordered w3-striped w3-border w3-small w3-hoverable" id="ratio" style="cursor:pointer">
                            <thead>
                                <tr class="w3-theme">
                                    <th class="w3-border-right">Nama</th>
                                    <th class="w3-border-right">{{yearSub}}</th>
                                    <th class="w3-border-right">{{year}}</th>
                                </tr>
                            </thead>
                        </table>    
                    </div>
                </div>
                <div class="w3-col l5 w3-margin-bottom w3-container">
                    <div class="w3-round-small">
                        <header class="w3-container w3-round w3-border-bottom w3-center cursor" style="height: 38px;" id="rp-itemstock">
                            <h4 style="text-transform: uppercase;">10 BEST CUSTOMER {{monthSub}}</h4>
                        </header>
                        <div class="w3-row w3-padding-16 w3-col l12">
                            <div class="w3-row">
                                <pie-chart :chartData="chartData" :options="chartOptions" :height="300"></pie-chart>
                            </div>
                        </div>
                        <div class="w3-col l12">
                            <ol>
                                <li v-for="item in recCharts" :key="item.tm_id">
                                    {{ item.tm_name }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w3-row">
                <div class="w3-col s12">
                    <span class="w3-btn w3-block w3-theme-l1 w3-left-align round-top-large w3-small">
                       FILTER &nbsp;<i class="fa fa-caret-down"></i> 
                    </span>
                    <div class="w3-animate-zoom w3-white">
                        <form class="w3-container" id="frmCust" v-on:submit.prevent="showCust" autocomplete="off">
                            <div class="w3-row w3-small">
                                <div class="w3-col m3 w3-padding">
                                    <label>Customer</label>
                                    <select v-model="recCust.status" class="w3-select w3-round-large w3-white" style="width: 100%;" required>
                                        <option value="" disabled selected>Pilih Status</option>
                                        <option value="2">On</option>
                                        <option value="1">Off > 3 Bulan</option>
                                        <option value="0">Off</option>
                                    </select>
                                </div>
                                <div class="w3-col m3 w3-padding">
                                    <label>Bulan</label>
                                    <select v-model="recCust.bulan" class="w3-select w3-round-large w3-white" required>
                                        <option value="" disabled selected>Pilih Bulan</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                                <div class="w3-col m3 w3-padding">
                                    <label>Tahun</label>
                                    <input v-model="recCust.tahun" class="w3-input w3-round-large w3-border-bottom" type="number" placeholder="Tahun" required>
                                </div>
                                <div class="w3-col m3 w3-center" style="padding-top: 34px;">
                                    <button type="submit" class="w3-btn w3-theme w3-round-large" form="frmCust"><i class="fa fa-search"></i>&nbsp; Show</button>
                                </div>  
                            </div>
                            <br>
                            <p>
                            </p>
                        </form>
                    </div>
                    <div class="scrl-x">
                        <table class="w3-table w3-striped w3-small w3-hoverable" id="reccust">
                            <thead>
                                <tr class="w3-theme-l3">
                                    <th class="w3-border-right">Customer ID</th>
                                    <th class="w3-border-right">Customer Name</th>
                                    <th class="w3-border-right">Alamat</th>
                                    <th class="w3-border-right">Tlp</th>
                                    <th class="w3-border-right">Last Transaction</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "../chart/PieChart.js";
import moment from "moment";

// import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Customer',
    components: {
        PieChart
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            card : {
                custon : 0,
                custoff : 0,
            },
            recCharts : [],
            chartData: {},
            chartOptions: {},
            departement : 'SUB',
            dept: [
                { text: 'SURABAYA', value: 'SUB' },
                { text: 'SEMARANG', value: 'SRG' }
            ],
            recsratiocust : [],
            recCust : {
                status : '',
                bulan : '',
                tahun : ''
            },
            recsCust : []
        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
            this.getDataPie();
        },
        showCust(event){
            let self = this;
            axios.post("dashboard/ApiDboard.php",{
                pfunction : 'filtercust',
                dept : self.departement,
                status : self.recCust.status,
                bulan : self.recCust.bulan,
                tahun : self.recCust.tahun
            }).then(function (response) {
                self.recsCust = response.data.rec;
                $('#reccust').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.recsCust,
                    columns: [
                        {"data": "CustID"},
                        {"data": "CustName"},
                        {"data": "Address"},
                        {"data": "Phones"},
                        {"data": "lastdt"}
                    ],
                    columnDefs: [
                    ],
                    dom: 'lfBrtip',
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        LoadData(){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("dashboard/ApiDboard.php",{
                pfunction : "ratiocust",
                dept : self.departement
            }).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.recsratiocust = response.data.rec;
                $('#ratio').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.recsratiocust,
                    columns: [
                        {"data": "1"},
                        {"data": "2",render: $.fn.dataTable.render.number( ',', '.', 2)},
                        {"data": "3",render: $.fn.dataTable.render.number( ',', '.', 2)}
                    ],
                    columnDefs: [
                    ],
                    order: [[ 2, "desc" ]],
                    dom: 'lfBrtip',
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            let param = {
                pfunction : 'statuscust',
                filter : 'cust',
                dept: self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.card.custoff = response.data.rec[0].tc_nilai;
                self.card.custon = response.data.rec[1].tc_nilai;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        getDataPie(){
            let self = this;
            let param = {
                pfunction : 'bestcust',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.recCharts = response.data.rec;
                self.chartData = {
                    labels: response.data.rec.map(entry => entry.tm_name),
                    datasets: [
                        {
                            anchor: "center",
                            borderWidth: 0,
                            backgroundColor: response.data.rec.map(entry => self.getColor()),
                            data: response.data.rec.map(entry => entry.tm_qty),
                            borderColor: "#FFFFFF",
                            hoverBorderWidth: 5,
                        }
                    ]
                }
            });      
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label:function (tooltipItem, data) {
                            return data['labels'][tooltipItem['index']];
                        },
                        footer:function (tooltipItem, data) {
                            let sum = 0;
                            let dataset = data.datasets[0];
                            let currentValue = dataset.data[tooltipItem[0].index];
                            let dataArr = dataset.data;
                            dataArr.map(data => {
                                sum += Number(data);
                            });
                            let percentage = (currentValue * 100 / sum).toFixed(2) + '%';
                            return "Persentase "+percentage;
                        }
                    }
                }
            }
        },
        setInit() {
            this.LoadData();
            this.getDataPie();
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777215).toString(16);
            return code;            
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
